import React, { FunctionComponent } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

interface Props {
  active: boolean;
  to: string;
}

const NavLink: FunctionComponent<Props> = ({ active, children, to }) => (
  <li
    className={clsx(
      'border-b-2 mx-3 pb-1 transition-all hover:border-green hover:text-green',
      active ? 'border-green text-green' : 'border-transparent',
    )}
  >
    <Link to={to}>{children}</Link>
  </li>
);

export default NavLink;
