import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import NavLink from './NavLink';

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  let pathname = '';

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-undef
    pathname = window.location.pathname;
  }

  useEffect(() => {
    function onClick(e: MouseEvent) {
      const target = e.target as HTMLElement;

      if (target?.closest('.mobile-nav-trigger') === null) {
        setShowMobileNav(false);
      } else if (target?.closest('.mobile-nav-trigger')) {
        setShowMobileNav(!showMobileNav);
      }
    }

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [showMobileNav]);

  return (
    <header className="bg-white font-Roboto sticky top-0 z-20 lg:relative">
      <nav className="bg-white container grid grid-cols-2 items-center mx-auto px-4 py-6 lg:py-8 lg:grid-cols-4">
        <Link to="/">
          <StaticImage
            alt="natural cafe market"
            className="w-full sm:w-48 md:w-56"
            placeholder="blurred"
            src="../images/logo.png"
          />
        </Link>

        <ul className="col-span-3 hidden justify-center lg:flex xl:col-span-2">
          <NavLink active={pathname === '/'} to="/">Home</NavLink>
          <NavLink active={pathname === '/catering'} to="/catering">Catering</NavLink>
          <NavLink active={false} to="/#menus">Menus</NavLink>
          <NavLink active={pathname === '/about'} to="/about">About Us</NavLink>
          <NavLink active={false} to="/#hours">Hours &amp; Location</NavLink>
          <NavLink active={pathname === '/contact'} to="/contact">Contact</NavLink>
        </ul>

        <div className="text-right lg:text-center">
          <button aria-controls="mobile-nav" className="mobile-nav-trigger p-4 lg:hidden" type="button">
            <FontAwesomeIcon icon={faBars} />
          </button>

          <a
            className="bg-green hidden px-6 py-3 rounded-md text-white transition-opacity hover:opacity-70 xl:inline-block"
            href="https://order.ubereats.com/washington-dc/food-delivery/Natural%20Cafe%20(Arlington)/ukjUWM-yTFyEVNZHu2LcbA/?utm_source=web-restaurant-manager"
          >
            Order Online
          </a>
        </div>
      </nav>

      <div className={clsx('absolute bg-white left-0 mobile-nav right-0 shadow-lg text-center top-full', showMobileNav && 'mobile-nav--active')} id="mobile-nav">
        <ul>
          <li className="py-3">
            <Link to="/">Home</Link>
          </li>
          <li className="py-3">
            <Link to="/catering">Catering</Link>
          </li>
          <li className="py-3">
            <Link to="/#menus">Menus</Link>
          </li>
          <li className="py-3">
            <Link to="/about">About Us</Link>
          </li>
          <li className="py-3">
            <Link to="/#hours">Hours &amp; Location</Link>
          </li>
          <li className="py-3">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
