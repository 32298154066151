/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FunctionComponent } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { faFacebook, faTwitter, faYelp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from './header';

const Layout: FunctionComponent = ({ children }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link href="https://fonts.googleapis.com/css2?family=Parisienne&amp;family=Roboto:wght@300;400;500&amp;display=swap" rel="stylesheet" />
    </Helmet>

    <div className="grid layout-grid min-h-screen">
      <Header />

      <main className="font-Roboto">{children}</main>

      <footer className="bg-grey-dark font-Roboto pb-4 pt-8 text-center md:text-left">
        <div className="container grid grid-cols-1 mx-auto px-4 text-white md:grid-cols-4">
          <div className="mb-8 self-center text-center md:col-span-2 md:mb-0 md:text-left">
            <StaticImage alt="" className="w-64" src="../images/logo-white.png" />
          </div>

          <div className="mb-4 md:mb-0">
            <h3 className="font-bold mb-4 text-lg">Navigation</h3>

            <ul>
              <li className="mb-2">
                <Link to="/">Home</Link>
              </li>
              <li className="mb-2">
                <Link to="/catering">Catering</Link>
              </li>
              <li className="mb-2">
                <Link to="/#menus">Menus</Link>
              </li>
              <li className="mb-2">
                <Link to="/about">About</Link>
              </li>
              <li className="mb-2">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-bold mb-4 text-lg">Social Media</h3>

            <ul className="flex justify-center md:justify-start">
              <li className="mr-8">
                <a href="https://www.facebook.com/NaturalCafeArlington" rel="noreferrer noopener" target="_blank">
                  <FontAwesomeIcon className="text-4xl" icon={faFacebook} />
                </a>
              </li>
              <li className="mr-8">
                <a href="https://twitter.com/naturalcafeva" rel="noreferrer noopener" target="_blank">
                  <FontAwesomeIcon className="text-4xl" icon={faTwitter} />
                </a>
              </li>
              <li>
                <a href="https://www.yelp.com/biz/natural-cafe-arlington" rel="noreferrer noopener" target="_blank">
                  <FontAwesomeIcon className="text-4xl" icon={faYelp} />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className="mt-12 text-center text-white">
          {`©${new Date().getFullYear()} Natural Cafe Market | Designed & Developed by `}
          <a href="https://exobyte.io" rel="noreferrer noopener" target="_blank">
            Exobyte
          </a>
        </p>
      </footer>
    </div>
  </>
);

export default Layout;
